<template>
    <el-container>
        <el-main>
            <!-- <router-link to="/extension/cicle/add">
                <el-button size="small" type="primary" icon="el-icon-plus">创建圈子</el-button>
            </router-link> -->
            <el-form class="sesrch">
                <div class="order-form-item" style="margin-bottom:10px">
                    <el-form-item label="圈子标题：">
                        <el-input size="small" placeholder="请输入圈子标题" v-model="form.title"></el-input>
                    </el-form-item>
                    <el-form-item label="发布人信息：">
                        <el-input size="small" placeholder="请输入发布人昵称" v-model="form.nick_name"></el-input>
                    </el-form-item>
                    <div class="item">
                        <span>发布日期：</span>
                        <div style="flex:1;">
                            <el-date-picker value-format="timestamp" v-model="form.timeVal" size="small" type="daterange"
                                range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                        </div>
                    </div>
                </div>
                <div class="order-form-item">
                    <el-form-item label="是否审核：">
                        <el-select size="small" v-model="form.status" placeholder="请选择">
                            <el-option v-for="item in status" :key="item.value" :label="item.label"
                                :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="">
                        <el-button size="small" type="primary" @click="getList()">搜索</el-button>
                        <el-button size="small" type="text" @click="cancelSearch">清空搜索条件</el-button>
                    </el-form-item>
                </div>

            </el-form>
            <div class="article-list">
                <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
                    <template slot="empty">
                        <No />
                    </template>

                    <el-table-column prop="create_time" label="圈子时间" align="center">
                        <template slot-scope="scope">
                            <span>{{ getDateformat(scope.row.create_time) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="圈子logo" align="center">
                        <template v-slot="{ row }">
                            <el-image :src="row.logo"></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column prop="title" label="圈子标题" align="center">
                        <template slot-scope="scope">
                            <div class="table_title">{{ scope.row.title }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="cate_name" label="分类名称" align="center"></el-table-column>
                    <el-table-column prop="nick_name" label="发布人" align="center"></el-table-column>
                    <el-table-column prop="add_num" label="加入人数" align="center"></el-table-column>

                    <el-table-column prop="status" label="是否审核" align="center">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.status === 1" size="small" :type="success">审核成功</el-tag>
                            <el-tag v-if="scope.row.status === 0" size="small" :type="info">审核中</el-tag>
                            <el-tag v-if="scope.row.status === -1" size="small" :type="danger">审核失败</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="120" align="center">
                        <template v-slot="{ row }">
                            <el-button type="text" @click="lookDetail(row, 1)">查看</el-button>
                            <el-button type="text" v-if="row.status == 0" @click="examine(row, 1)">通过</el-button>
                            <el-button type="text" v-if="row.status == 0" @click="examine(row, 2)">驳回</el-button>
                            <el-button type="text" v-if="row.status == -1" @click="lookDetail(row, 4)">审核详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <Paging class="paging" :total="total_number" :page="form.page" :pageNum="form.rows" @updatePageNum="updateData">
            </Paging>

            <!-- 查看详情弹框 -->
            <el-dialog title="查看详情" :visible.sync="dialogVisibleDetail" width="52%">
                <el-form label-width="120px" class="demo-ruleForm dialogBox">
                    <el-form-item label="圈子名称：">
                        {{ lookOne.title }}
                    </el-form-item>
                    <el-form-item label="圈子logo：">
                        <el-image :z-index="9999" style="width: 40px; height: 40px" :src="lookOne.logo"
                            :preview-src-list="[lookOne.logo]"></el-image>
                    </el-form-item>
                    <el-form-item label="圈子类型：">
                        {{ lookOne.cate_name }}
                    </el-form-item>
                    <el-form-item label="简单介绍：">
                        {{ lookOne.cicle_detail }}
                    </el-form-item>
                    <el-form-item label="发起人：">
                        {{ lookOne.nick_name }}
                    </el-form-item>
                    <el-form-item label="联系电话：">
                        {{ lookOne.contact_phone }}
                    </el-form-item>
                    <!-- <el-form-item label="门店电话：">
                        {{ lookOne.store_phone }}
                    </el-form-item> -->
                    <el-form-item label="圈子地址：">
                        {{ lookOne.address }}
                    </el-form-item>

                    <el-form-item label="圈子介绍图">
                        <el-image v-for="(image, index) in lookOne.cicle_banners" :key="index" :src="image.banner"
                            :fit="fit" :alt="alt"></el-image>
                    </el-form-item>

                </el-form>
            </el-dialog>

            <!-- 查看审核详情 -->
            <el-dialog title="审核详情" :visible.sync="dialogVisibleReject" width="52%">
                <el-form label-width="120px" class="demo-ruleForm">
                    <el-form-item label="审核结果：">驳回</el-form-item>
                    <el-form-item label="驳回原因：">
                        <el-input style="width: 500px" type="textarea" :rows="3" disabled
                            v-model="lookOne.reason"></el-input>
                    </el-form-item>
                </el-form>
            </el-dialog>

            <!-- 审核驳回 -->
            <el-dialog title="审核" :visible.sync="rejectDialog" width="52%">
                <el-form label-width="120px" class="demo-ruleForm">
                    <el-form-item label="审核结果：">驳回</el-form-item>
                    <el-form-item label="驳回原因：">
                        <el-input style="width: 500px" type="textarea" :rows="3" v-model="rejectTxt"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="rejectDialog = false">取 消</el-button>
                    <el-button type="primary" @click="rejectSure">确 定</el-button>
                </span>
            </el-dialog>
        </el-main>
    </el-container>
</template>
  
<script>
import Paging from '@/components/paging.vue'
import { getDateformat } from '@/util/getDate'
export default {
    components: {
        Paging
    },
    data() {
        return {
            total: 0,
            form: {
                page: 1,
                rows: 10,
                title: '',
                nick_name: "",
                timeVal: '',
                status: '',
            },
            status: [
                {
                    value: '',
                    label: '全部'
                },
                {
                    value: 1,
                    label: '已审核'
                },
                {
                    value: 0,
                    label: '审核中'
                },
                {
                    value: -1,
                    label: '审核驳回'
                },
            ],
            typeList: [],
            list: [],
            rejectTxt: null,
            lookOne: {},
            dialogVisibleReject: false,
            dialogVisibleDetail: false,
            rejectDialog: false,
        }
    },
    created() {

        let utils = this.$store.state.utils
        if (utils.is_record) {
            this.form.page = utils.page
            this.form.rows = utils.rows
            utils.is_record = !1
        }
        this.getType()
        this.getList()

    },
    methods: {
        lookDetail(row, style) {
            this.lookOne = row;
            if (style == 1) {
                this.dialogVisibleDetail = true;
            } else if (style == 4) {
                this.dialogVisibleReject = true;
            }
        },
        getType() {
            this.$axios
                .post(this.$api.samecity.community.classifyList)
                .then((res) => {
                    if (res.code == 0) {
                        this.typeList = res.result.list.list
                    }
                })
        },
        cancelSearch() {
            this.form = {
                page: 1,
                rows: 10,
                title: '',
                nick_name: "",
                timeVal: '',
                is_show: -1,
                is_tops: -1
            }
            this.getList()
        },
        updateData(val, status) {
            if (status == 0) {
                this.form.rows = val
                this.getList()
            } else {
                this.form.page = val
                this.getList()
            }
        },
        getList() {
            let data = this.form
            if (data.timeVal) {
                let arr = []
                data.timeVal.forEach(item => {
                    arr.push(item / 1000)
                })
                data.timeVal = arr
            }
            
            this.$axios.post(this.$api.samecity.community.cicleList, data)
                .then((res) => {
                    if (res.code == 0) {
                        let list = res.result.list
                        this.list = list
                        this.total_number = res.result.total_number
                    } else {
                        this.$message.error(res.msg)
                    }
                })
        },
        detail(id) {
            this.$router.push({
                path: '/extension/cicle/detail',
                query: {
                    id: id,
                    page: this.form.page,
                    rows: this.form.rows
                }
            })
        },
        tableChange(row) {
            let form = {
                title: row.title,
                cate_id: row.cate_id,
                avater: row.avater,
                nick_name: row.nick_name,
                is_show: row.is_show,
                is_tops: row.is_tops,
                content_type: row.content_type,
                content: row.content,
                content_data: row.content_data,
                id: row.id
            }
            this.$axios.post(this.$api.samecity.community.postsEdit, form).then((res) => {
                if (res.code == 0) {
                    this.$message({
                        message: '编辑成功',
                        type: 'success'
                    })
                    this.getList()
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        // 审核函数
        examine(row, style) {
            let that = this;
            if (style == 1) {
                this.$confirm('确认通过审核！！！', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                    .then(() => {
                        that.$axios
                            .post(that.$api.samecity.community.upStatus, {
                                id: row.id,
                                status: 1,
                            })
                            .then(res => {
                                if (res.code == 0) {
                                    that.$message.success('审核成功');
                                    this.getList();
                                } else {
                                    that.$message.error('审核失败');
                                }
                            });
                    })
                    .catch();
            } else if (style == 2) {
                this.lookOne = row;
                this.rejectDialog = true;
            }
        },
        // 确认驳回
        rejectSure() {
            if (this.rejectTxt == null) {
                this.$message.warning('请填写驳回原因！！');
                return;
            }
            let that = this;
            this.$axios
                .post(that.$api.samecity.community.upStatus, {
                    id: that.lookOne.id,
                    status: -1,
                    reason: that.rejectTxt,
                })
                .then(res => {
                    if (res.code == 0) {
                        that.$message.success('驳回成功');
                        this.rejectDialog = false;
                        this.getList();
                    } else {
                        that.$message.error('驳回失败');
                    }
                });
        },
        getStatus(status) {
            if (status === -1) {
                return '审核拒绝';
            } else if (status === 'inactive') {
                return '未激活';
            } else {
                return '未知';
            }
        },
        getDateformat(val) {
            return getDateformat(val)
        }
    }
}
</script>
  
<style lang="less" scoped>
.el-table {
    border-left: 1px solid #e8eef1;
    border-right: 1px solid #e8eef1;

    /deep/ .el-input__inner {
        border-color: #fff;
    }

    /deep/ .el-input__inner:focus {
        border-color: #409eff;
    }
}

.el-container {
    .el-main {
        height: 100%;
        background: #fff;
        display: flex;
        flex-direction: column;

        .sesrch {
            margin-top: 10px;
            margin-bottom: 10px;
            padding: 10px 15px;
            background: #f8f9fa;

            .order-form-item {
                display: flex;
                color: #606266;

                .item {
                    margin-right: 20px;
                    width: 350px;
                    display: flex;
                    align-items: center;

                    span {
                        width: 88px;
                        font-size: 14px;
                        margin-right: 12px;
                        text-align: right;
                    }

                    .el-date-editor {
                        width: 100%;
                    }
                }
            }

            .el-form-item {
                margin-bottom: 0;
                margin-right: 20px;
                display: flex;

                .el-input {
                    width: 250px;
                }

                .el-select {
                    width: 250px;
                }

                /deep/.el-form-item__label {
                    width: 100px;
                }
            }
        }

        .article-list {
            flex: 1;
        }
    }
}

.el-image {
    width: 40px;
    height: 40px;
    margin-right: 5px;
    border-radius: 50%;
}

.table_title {
    text-align: left;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
</style>
  